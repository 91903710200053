import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import initTemplate from "../utils/initTemplate";
import MenuLinks, { sideBarLinks } from "../utils/MenuLinks";
import UserHavePermission from "../utils/UserHavePermission";
import documentacion from "../assets/img/documentacion.png";
import useAxios from "../hooks/useAxios";
import { useFeedBack } from "../contexts/FeedBackContext";

const Sidenav = ({ toggleMenu }) => {

    const location = useLocation();

    const { setLoading } = useFeedBack();

    const [path, setPath] = useState('');

    const [{ loading: loginLoading }, login] = useAxios({ method: 'POST' }, { useCache: false, manual: true });

    useEffect(() => {
        setLoading({
            show: loginLoading,
            message: 'Ingresando',
        });
    }, [loginLoading]);

    useEffect(() => {
        initTemplate();
    }, [])

    useEffect(() => {
        setPath(location?.pathname)
    }, [location?.pathname]);

    const linkHaveAdminModules = (modules) => {
        return modules?.length > 0 ? true : false;
    }

    const handleLogin = (code, siteUrl) => {
        login({ url: '/auth/generate-token', data: { systemCode: code } }).then((response) => {
            window.open(`${siteUrl}?token=${window.encodeURIComponent(response?.data?.token)}`, '_blank');
        });
    }

    return <div className="left-sidenav custom-menu animate__animated animate__fadeInLeft" id="sidenav">
        <div className="d-lg-none">
            <button className="btn waves-effect waves-primary" onClick={() => toggleMenu?.()}>
                <i className="ti-arrow-left text-primary nav-icon"></i>
            </button>
        </div>
        <ul className="metismenu left-sidenav-menu">
            {
                sideBarLinks?.map((sideLink, i) => {
                    return (
                        <li key={i}>
                            <Link style={{ textTransform: 'capitalize' }} to="#" title={sideLink?.name}>
                                <i className={sideLink?.icon}></i>
                                <span>{sideLink?.name?.length > 13 ? `${sideLink?.name?.slice?.(0, 13)}...` : sideLink?.name}</span>
                                <span className="menu-arrow">
                                    <i className="mdi mdi-chevron-right"></i>
                                </span>
                            </Link>
                            <ul className="nav-second-level">
                                {
                                    UserHavePermission(sideLink?.adminPermissions) && linkHaveAdminModules(sideLink?.adminLinks) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to={sideLink?.adminUrl}>
                                                <i className="ti-control-record"></i>
                                                Administrar
                                            </Link>
                                        </li>
                                        :
                                        null
                                }
                                <li
                                    className="nav-item">
                                    <Link className="nav-link" to={sideLink?.managementUrl}>
                                        <i className="ti-control-record"></i>
                                        Gestionar
                                    </Link>
                                </li>
                                <li
                                    className="nav-item">
                                    <Link className="nav-link" to={sideLink?.aplicationsUrl}>
                                        <i className="ti-control-record"></i>
                                        Aplicaciones
                                    </Link>
                                </li>
                                <li
                                    className="nav-item">
                                    <Link className="nav-link" to={sideLink?.externalLinksUrl}>
                                        <i className="ti-link"></i>
                                        App Externas
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )
                })
            }
            <li>
                <Link style={{ textTransform: 'capitalize' }} to="/utilidades-ti" title="Utilidades Ti">
                    <i className="ti-desktop"></i>
                    <span>Utilidades TI</span>
                </Link>
            </li>
            <li>
                <button type="button" onClick={() => handleLogin('sys-012', 'https://csidocumentos.csiltda.cl/iniciar-sesion')} className="module-link" title="Biblioteca Documental">
                    <img src={documentacion} alt="" style={{ height: 20, width: 20, marginRight: 10 }} />
                    <span>Biblioteca Documental</span>
                </button>
            </li>
        </ul>
    </div >;
}

export default Sidenav;